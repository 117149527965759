<script>
import CookiesMixin from './CookiesMixin.vue';

const cookieKey = 'tooltip-disabled';

export default {
    name: "ToolTipMixin",
    mixins: [CookiesMixin],
    data() {
        return {
            isToolTipDisabled: false
        };
    },
    mounted() {
        this._refresh();
    },
    methods: {
        _refresh() {
            this.isToolTipDisabled = this.getTooltipsState();
        },

        changeTooltipsState(state) {
            this.setCookieValue(cookieKey, state);
            this._refresh();
        },

        getTooltipsState() {
            return this.getCookieValue(cookieKey, true, false);
        }
    }
}
</script>